import {
  A2500SectorsApi,
  A4000SectorsApi,
  A4500SectorsApi,
  SectorItem,
} from '@vegga-api-clients/irrigation-control-service';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { HttpClient } from '../../http';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { VeggaFacade } from '../vegga-facade';
import { Observable } from 'rxjs';

@Injectable('sectorsFacade')
export class SectorsFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  @Inject('httpClient') private httpClient: HttpClient;

  private sectorsResponse = new VeggaResponse<SectorItem[]>();
  private A2500SectorsApi: A2500SectorsApi;
  private A4000SectorsApi: A4000SectorsApi;
  private A4500SectorsApi: A4500SectorsApi;

  constructor() {
    this.A2500SectorsApi = new A2500SectorsApi();
    this.A2500SectorsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.A4000SectorsApi = new A4000SectorsApi();
    this.A4000SectorsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.A4500SectorsApi = new A4500SectorsApi();
    this.A4500SectorsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get loader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get isLoading$(): Observable<boolean> {
    return this.loader.isLoading$;
  }

  get sectors$(): Observable<SectorItem[]> {
    return this.sectorsResponse.value$;
  }

  get sectors(): SectorItem[] {
    return this.sectorsResponse.value;
  }

  /**
   * @deprecated
   * Used in legacy sector factory, remove when
   * sectors are migrated
   */
  set sectors(sectors: SectorItem[]) {
    this.sectorsResponse.set([...sectors]);
  }

  getA2500Sectors(id: number) {
    const req$ = this.A2500SectorsApi.getSectorList({ id });

    const subscription = handleResponse(req$, this.sectorsResponse).subscribe({
      next: (sectors) => {
        this.sectorsResponse.set(sectors);
      },
      error: (err) => {
        this.sectorsResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  getA4000Sectors(id: number) {
    const req$ = this.A4000SectorsApi.getSectorListA4000({ id });

    const subscription = handleResponse(req$, this.sectorsResponse).subscribe({
      next: (sectors) => {
        this.sectorsResponse.set(sectors);
      },
      error: (err) => {
        this.sectorsResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  getA4500Sectors(id: number) {
    const req$ = this.A4500SectorsApi.getSectorListA4500({ id });

    const subscription = handleResponse(req$, this.sectorsResponse).subscribe({
      next: (sectors) => {
        this.sectorsResponse.set(sectors);
      },
      error: (err) => {
        this.sectorsResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  clearSectorsResponse() {
    this.sectorsResponse.clear();
  }
}
