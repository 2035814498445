import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { HttpClient } from '../../http';

@Injectable('agronicFacade')
export class AgronicFacade {
  @Inject('httpClient') private httpClient: HttpClient;
  private authLegacyResponse = new VeggaResponse();

  /**
    * Legacy units/devices response, fetched from old agronic api
    */
  get authLegacy(): Observable<any> {
    return this.authLegacyResponse.value$;
  }

  /**
    * Legacy units/devices error, fetched from old agronic api
    */
  get authLegacyError(): Observable<any> {
    return this.authLegacyResponse.error$;
  }

  /**
   * Legacy method that points to old agronic endpoint
   * as BE is still in development.
   * TODO: Implement method with new auth endpoints
   */
  loadAuthLegacy() {
    const req$ = this.httpClient.get<any>(`${environment.AGRONICWEB_API_URL}/api/v1/users/auth`, {});

    handleResponse(req$, this.authLegacyResponse).subscribe({
      next: (fert) => { this.authLegacyResponse.set(fert) },
      error: (err) => { this.authLegacyResponse.setError(err, {}) },
    });
  }
}
