//@ts-ignore: In-browser module import
import { HttpEvent } from '@vegga-api-clients/irrigation-control-service';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { HttpHandler, HttpInterceptor, HttpMethod } from '../http';
import { HttpRequest } from '../http/request';

export interface VeggaToastConfig {
  message: string;
  statusClass: string;
}

const EXCLUDED_PATHS = [
  'io/status'
];

export class ServerMessageInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return (next.handle(req) as Observable<HttpEvent<any>>).pipe(
      tap(() => {
        const isExcluded = EXCLUDED_PATHS.some(excludedPath => req.url.includes(excludedPath));
        if (isExcluded) {
          return;
        }

        switch (req.method) {
          case HttpMethod.POST:
          case HttpMethod.PUT:
            this.showVeggaToast({ message: 'Guardado con éxito', statusClass: 'success' });
            break;
          case HttpMethod.DELETE:
            this.showVeggaToast({ message: 'Eliminado con éxito', statusClass: 'success' });
            break;
        }
      }),
      catchError((error: any) => {
        const message = (error.error && error.error.errorCode) || error.errorCode || 'Se ha producido un error'
        this.showVeggaToast({ message, statusClass: 'error' });
        return throwError(() => of(error));
      }))

  }

  /**
   * Shows a toast with passed message and style
   */
  private showVeggaToast({ message, statusClass }: VeggaToastConfig): void {
    const toast = document.querySelector('vegga-toast-manager');
    (toast as any).create({ message, statusClass })
  }
}