//@ts-ignore: In-browser module import
import { getToken, showLoader, hideLoader } from '@vegga/front-utils';
import { Observable, catchError, finalize, from, of, switchMap, throwError } from 'rxjs';
import { HttpErrorResponse, HttpHandler, HttpInterceptor } from '../http';
import { HttpRequest } from '../http/request';
import { navigateToUrl } from 'single-spa';
import { HttpMethod } from '@vegga-api-clients/irrigation-control-service';

export class AuthInterceptor implements HttpInterceptor {
  countRequest: number;
  excludePathItems: boolean;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.countRequest && req.method !== HttpMethod.GET) {
      showLoader();
    }

    this.excludePathItems = req.url.includes('/core-service/usernames') ||
      req.url.includes('/core-service/register') ||
      req.url.includes('/core-service/users/resetPassword') ||
      /(?=.*\/core-service\/migrations\/users)(?!.*\/subusers)(.+)/.test(req.url);

    const SECURED_APIS = ['/agronic/api/v1/', '/agronic-service/api/v1/', '/core-service/', '/irrigation-control/', '/irrigation-control-service/'];
    const isSecuredApi = SECURED_APIS.some(secApi => req.url.includes(secApi))

    return from(isSecuredApi && !this.excludePathItems ? getToken() : of({})).pipe(
      switchMap((token) => {
        if (token) {
          req.headers['authorization'] = `Bearer ${token}`;
        }

        this.countRequest++;
        return next.handle(req).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401 && !/\/core-service\/migrations\/users$/g.test(req.url)) {
              navigateToUrl('/authentication/sign-in');
            }
            return throwError(() => err);
          }),
          finalize(() => {
            this.countRequest--;
            if (!this.countRequest) {
              hideLoader();
            }
          })
        );
      })
    );
  }
}